<div *ngIf="entry" class="main-content">
    <mat-card>
        <mat-card-header>
            <mat-card-title>Pending e-mail verification.</mat-card-title>
        </mat-card-header>
        <mat-card-content>
            <p>Before proceeding with your deposition, please click on the verification link you were sent when you
                created your deposition.

            <p><strong>Why do I need to do this?</strong> Verifying your email before you begin your deposition ensures
                that we have a working e-mail associated with your deposition. It also allows us to verify ownership of
                the deposition.</p>
            <button mat-raised-button (click)="resendValidationEmail()" color="accent">Resend validation email</button>
        </mat-card-content>
    </mat-card>
</div>
