<ng-container>
    <app-file-uploader *ngIf="saveframe.category == 'deposited_data_files'; else normal"
                       [entry]="saveframe.parent"></app-file-uploader>
    <ng-template #normal>
        <div *ngIf="saveframe.display == 'Y' || (saveframe.parent.showAll && saveframe.display == 'N')">

            <table>
                <colgroup>
                    <col class="tag-column">
                    <col class="value-column">
                </colgroup>

                <thead>
                <tr [matTooltip]="saveframe.schemaValues['group_view_help']">
                    <th *ngIf="saveframe.tags[0]" (contextmenu)="saveframe.log()">
                        <a class="header-text">{{saveframe.tags[0].schemaValues['ADIT category view name']}}
                          <mat-icon routerLink="/help/molecular-assembly" *ngIf="saveframe.category == 'assembly'"
                                    class="pointer" matTooltip="Molecular assembly help">help_outline
                          </mat-icon></a>
                    </th>
                    <th>
                        <div class="right-float">
                            <button mat-raised-button class="saveframe-button"
                                    *ngIf="saveframe.schemaValues['ADIT replicable'] === 'Y' && !saveframe.deleted"
                                    (click)='saveframe.duplicate(); processChange()' color="accent"
                                    matTooltip="Create a new section of this type with the same data as this section."
                                    [disabled]="saveframe.parent.deposited">Duplicate
                            </button>
                            <button mat-raised-button class="saveframe-button"
                                    *ngIf="saveframe.schemaValues['ADIT replicable'] === 'Y' && !saveframe.deleted"
                                    (click)='saveframe.duplicate(true); processChange()' color="accent"
                                    matTooltip="Create a new and blank section of this type."
                                    [disabled]="saveframe.parent.deposited">Create new
                            </button>
                            <button mat-raised-button class="saveframe-button"
                                    *ngIf="saveframe.saveframesInCategory > saveframe.schemaValues['mandatory_number'] && !saveframe.deleted"
                                    (click)='deleteSaveframe()' color="accent"
                                    matTooltip="Delete this entire section."
                                    [disabled]="saveframe.parent.deposited">Delete
                            </button>
                            <button mat-raised-button class="saveframe-button"
                                    *ngIf="!saveframe.deleted"
                                    (click)='clearSaveframe()' color="accent"
                                    matTooltip="Clear all the data in this section."
                                    [disabled]="saveframe.parent.deposited">Clear data
                            </button>
                            <button mat-raised-button class="saveframe-button"
                                    *ngIf="saveframe.deleted"
                                    (click)='saveframe.restore(); processChange()' color="accent"
                                    matTooltip="Restore this section. (Undo the previous delete.)"
                                    [disabled]="saveframe.parent.deposited">Restore
                            </button>
                            <button mat-raised-button class="saveframe-button" *ngIf="showCategoryLink"
                                    [routerLink]="['/entry', 'saveframe', saveframe.category]" color="accent">
                                View sections of this category
                            </button>
                        </div>
                    </th>
                </tr>
                </thead>
                <tbody>
                <ng-container *ngFor="let saveframeTag of saveframe.tags">
                    <tr *ngIf="saveframeTag.display == 'Y' || (saveframe.parent.showAll && saveframeTag.display == 'N')"
                        (contextmenu)="saveframeTag.log()">
                        <td *ngIf="saveframeTag.display == 'Y'; else non_mandatory" class="tag-description"
                            [matTooltip]="saveframeTag.schemaValues['Interface'] + ' Click for more information.'"
                            [innerHTML]="saveframeTag.schemaValues['Prompt'] + '<span class=\'mandatory\'>*</span>'"
                            (click)="helpClick(saveframeTag, tag_info_box)">
                        </td>
                        <ng-template #non_mandatory>
                            <td class="tag-description"
                                [matTooltip]="saveframeTag.schemaValues['Interface'] + ' Click for more information.'"
                                [innerHTML]="saveframeTag.schemaValues['Prompt']"
                                (click)="helpClick(saveframeTag, tag_info_box)">
                            </td>
                        </ng-template>
                        <td [ngClass]="{'invisible': true, 'invalid': !saveframeTag.valid}">
                            <app-tag [tag]='saveframeTag' [unique_identifier]="'_' + saveframe.name"></app-tag>
                        </td>
                    </tr>
                </ng-container>
                </tbody>
            </table>

            <div #tag_info_box>
              <div *ngIf='activeTag' class='tag-info-box'>
                  <b [innerHTML]="activeTag.schemaValues['Prompt']"></b>
                  <p [innerHTML]="activeTag.schemaValues['Interface']"></p>
                  <p *ngIf="activeTag.schemaValues['Example'] != '?'"
                     [innerHTML]="'<b>Example:</b> ' + activeTag.schemaValues['Example']"></p>
                  <p><i>{{activeTag.fullyQualifiedTagName}}</i></p>
                  <p *ngIf='activeTag.validationMessage' class='mandatory'>{{activeTag.validationMessage}}</p>
              </div>
            </div>

            <ng-container *ngFor='let loop of saveframe.loops'>
                <app-loop *ngIf="loop.display == 'Y' || (loop.display == 'N' && saveframe.parent.showAll)"
                          [loop]='loop'></app-loop>
            </ng-container>
        </div>
    </ng-template>
</ng-container>
