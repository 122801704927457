<div class="loop-wrapper-div">
    <div>
        <table>
            <colgroup>
                <col span="1" class="button-column">
            </colgroup>
            <thead>
            <tr (contextmenu)="log()">
                <th colspan=999 [matTooltip]="loop.schemaValues['group_view_help']">
                    {{loop.data[0][0].schemaValues['ADIT category view name']}}
                    <button mat-raised-button *ngIf="loop.category == '_Citation_author' && loop.empty"
                            (click)="copyAuthors()" color="accent">Copy authors from 'Entry information'
                    </button>
                </th>
            </tr>
            <tr>
                <th></th>
                <ng-container *ngFor='let sampleTag of loop.data[0]; let col = index'>
                    <th *ngIf="loop.displayTags[col] == 'Y' || (loop.displayTags[col] == 'N' && loop.parent.parent.showAll)"
                        class="tag-header" (click)="helpClick(sampleTag, tag_info_box)"
                        [matTooltip]="sampleTag.schemaValues['Interface'] + ' Click for more information.'">{{sampleTag.schemaValues['Prompt']}}
                        <span *ngIf="loop.displayTags[col] == 'Y'" class="mandatory">*</span>
                    </th>
                </ng-container>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let row of loop.data; let r = index">
                <td>
                    <button mat-raised-button
                            *ngIf="(loop.category != '_Contact_person' && loop.data.length > 1) || (loop.category == '_Contact_person' && r > 0 && loop.data.length > 2)"
                            (click)='deleteRow(r)' color="accent" class="square-button" matTooltip="Remove this row of data."
                            [disabled]="loop.parent.parent.deposited">-
                    </button>
                </td>
                <ng-container *ngFor="let col of row; let c = index">
                    <td *ngIf="loop.displayTags[c] == 'Y' || (loop.displayTags[c] == 'N' && loop.parent.parent.showAll)"
                        [ngClass]="{'invisible': true,
	      		              'invalid': !col.valid && (col.display == 'Y' || (col.display == 'N' && loop.parent.parent.showAll)),
	      		              'greyed-out': (!(col.display == 'Y' || (col.display == 'N' && loop.parent.parent.showAll)))}">
                        <app-tag *ngIf="col.display == 'Y' || (col.display == 'N' && loop.parent.parent.showAll)"
                                 [tag]='col' [unique_identifier]="'_' + loop.parent.name + '_' + r + '_' + c"
                                 (contextmenu)="col.log()"></app-tag>
                    </td>
                </ng-container>
            </tr>
            <tr>
                <td colspan=1>
                    <button mat-raised-button (click)='addRow()' color="accent" class="square-button"
                            matTooltip="Add another row of data." [disabled]="loop.parent.parent.deposited">+
                    </button>
                </td>
                <td colspan=999></td>
            </tr>

            </tbody>
        </table>
    </div>

    <div #tag_info_box>
      <div *ngIf='activeTag' class='tag-info-box'>
          <b>{{activeTag.schemaValues['Prompt']}}</b>
          <p>{{activeTag.schemaValues['Interface']}}</p>
          <p *ngIf="activeTag.schemaValues['Example'] != '?'"><b>Example:</b> {{activeTag.schemaValues['Example']}}</p>
          <p><i>{{activeTag.fullyQualifiedTagName}}</i></p>
          <p *ngIf="activeTag.validationMessage" class="mandatory">{{activeTag.validationMessage}}</p>
      </div>
    </div>
</div>
