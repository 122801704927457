<label *ngIf="tag.interfaceType == 'country'">
    <select
            [(ngModel)]="tag.value"
            [ngClass]="{'type-error': !tag.valid, 'tag-input': true, 'crs-country': true}"
            (change)="validateTag()"
            [matTooltip]="tag.validationMessage"
            [attr.data-region-id]="'state'+getRow()"
            [attr.data-default-value]="tag.value"
            [disabled]="tag.disabled"
            data-preferred="US,GB,CN,JP,AU,MX">
    </select>
</label>

<label *ngIf="tag.interfaceType == 'state'">
    <select
            [(ngModel)]="tag.value"
            [ngClass]="{'type-error': !tag.valid, 'tag-input': true}"
            (change)="validateTag()"
            [matTooltip]="tag.validationMessage"
            [id]="'state'+getRow()"
            [attr.data-default-value]="tag.value"
            [disabled]="tag.disabled"
            data-default-option="Select State or Province">
    </select>
</label>


<label *ngIf="tag.interfaceType == 'standard'">
    <input
            [(ngModel)]="tag.value"
            [ngClass]="{'type-error': !tag.valid, 'tag-input': true}"
            [placeholder]="tag.schemaValues['default value']"
            (change)="validateTag()"
            [matTooltip]="tag.validationMessage"
            [disabled]="tag.disabled">
  <!--
     Those don't play nicely with our validation
    [type]="tag.dataType"
    [attr.step]="tag.schemaValues['BMRB data type'] == 'float' ? 'any' : null"
            -->
</label>

<label *ngIf="tag.interfaceType == 'closed_enum'">
  <mat-select [(ngModel)]="tag.value"
              [ngClass]="{'type-error': !tag.valid, 'tag-input': true}"
              (ngModelChange)="validateTag()"
              [matTooltip]="tag.validationMessage"
              [disabled]="tag.disabled">
    <mat-option *ngIf="tag.value && tag.validationMessage" disabled [value]="tag.value" class="type-error">{{tag.value}} -
      Error: {{tag.validationMessage}}</mat-option>
    <ng-container *ngFor="let val of tag.enums">
      <mat-option *ngIf="tag.value != val[0]" [value]="val[0]" [matTooltip]="val[1]">{{val[0]}}</mat-option>
      <mat-option *ngIf="tag.value == val[0] && !tag.validationMessage" [value]="tag.value" [matTooltip]="val[1]">{{tag.value}}</mat-option>
    </ng-container>
  </mat-select>
</label>

<label *ngIf="tag.interfaceType == 'sf_pointer' || tag.interfaceType == 'experiment_pointer'">
    <mat-select [(ngModel)]="tag.value"
            [ngClass]="{'type-error': !tag.valid, 'tag-input': true}"
            (ngModelChange)="validateTag()"
            [matTooltip]="tag.validationMessage"
            [disabled]="tag.disabled" panelClass="closed_enum_option">
        <mat-option *ngFor="let frameLink of tag.frameLink" [value]="frameLink[0]"
                [ngClass]="{'type-error': !tag.valid}">{{frameLink[1]}}</mat-option>
    </mat-select>
</label>

<ng-container *ngIf="tag.interfaceType == 'open_enum'">
  <label>
    <input matInput
           [(ngModel)]="tag.value"
           [ngClass]="{'type-error': !tag.valid, 'tag-input': true}"
           (change)="validateTag()"
           (ngModelChange)="filter()"
           (focus)="filter()"
           [matTooltip]="tag.validationMessage"
           [disabled]="tag.disabled"
           [matAutocomplete]="auto">
  </label>

  <mat-autocomplete #auto="matAutocomplete" (optionSelected)="validateTag()" panelWidth="auto" class="closed_enum_option">
    <mat-option *ngFor="let option of filteredOptions" [value]="option[0]" [matTooltip]="option[1]">{{option[0]}}</mat-option>
  </mat-autocomplete>
</ng-container>


<ng-container *ngIf="tag.interfaceType == 'yes_no'">
    <div [ngClass]="{'type-error': !tag.valid, 'tag-input': true}"
         [matTooltip]="tag.validationMessage">

        <mat-radio-group [(ngModel)]="tag.value" [disabled]="tag.disabled" (change)="validateTag()">
            <mat-radio-button class="first-button" value="yes">Yes</mat-radio-button>
            <mat-radio-button value="no">No</mat-radio-button>
        </mat-radio-group>
    </div>
</ng-container>

<ng-container *ngIf="tag.interfaceType == 'text'">
    <label>
    <textarea [(ngModel)]="tag.value"
              [ngClass]="{'type-error': !tag.valid, 'tag-input': true}"
              [placeholder]="tag.schemaValues['default value']"
              (change)="validateTag()"
              [matTooltip]="tag.validationMessage"
              (ngModelChange)="recalculateHeight()"
              [style.height.em]="height"
              [disabled]="tag.disabled"></textarea>
    </label>

</ng-container>

