<table class="full_width" *ngIf="entry">
    <colgroup>
        <col class="index-column">
        <col class="file-status-column">
        <col class="file-type-column">
    </colgroup>

    <thead>
    <tr>
        <th colspan="2" (contextmenu)="logFileUploadSaveframe()">
            <a class="header-text">Deposit data files</a>
        </th>
        <th>
            <div class="right-float">
                <button mat-raised-button *ngIf="showCategoryLink" color="accent"
                        [routerLink]="['/entry', 'saveframe', 'deposited_data_files']">
                    View sections of this category
                </button>
            </div>
        </th>
    </tr>
    </thead>
    <tbody>

    <tr *ngFor="let file of entry.dataStore.dataFiles; let i = index">
        <td><p class="center-text">{{i + 1}}</p></td>
        <td>
            <div class="file-upload">
                <div *ngIf="file.fileName">
                    <button mat-raised-button [disabled]="entry.deposited" (click)="deleteFile(file.fileName)"
                            color="primary" class="square-button">-
                    </button>
                    <a download *ngIf="file.percent == 100; else uploading"
                       [href]="serverURL + '/' + entry.entryID + '/file/' + file.fileName"
                       target="_blank">{{file.fileName}}</a>
                    <ng-template #uploading>{{file.fileName}}</ng-template>
                </div>
                <mat-progress-bar *ngIf="file.fileName && file.percent != 100" mode="determinate"
                                  [value]="file.percent" color="#33527b">Uploading file:
                </mat-progress-bar>
            </div>
        </td>
        <td [ngClass]="{'invisible': true, 'invalid': !file.control.value.length}">
            <div class="file-upload">
                <mat-form-field>
                    <mat-select (selectionChange)="updateAndSaveDataFiles()" [formControl]="file.control"
                                placeholder="Please select the data type(s) for this file."
                                multiple required>
                        <mat-option *ngFor="let dataType of file.dropDownList" [value]="dataType"
                                    title="{{dataType[3]}}">{{dataType[0]}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </td>
    </tr>

    <tr>
        <td colspan="1"></td>
        <td colspan="1">
            <input type="file" hidden #inputFile (change)="selectFile($event)" multiple/>
            <button mat-raised-button color="primary" (click)="openInput()" id="fileUploadButton"
                    [disabled]="entry.deposited">
                Please select a file to upload.
            </button>
        </td>
        <td class="drag-td" (drop)="onDropFile($event)" (dragover)="onDragOverFile($event)">
            <div class="drag-box">
              <div class="drag-text" *ngIf="!entry.deposited">Or drag and drop files <b>or</b> folders here.</div>
            </div>
        </td>
    </tr>
    </tbody>
</table>
