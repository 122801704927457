<div class="line highlight one_indent">
    <span class="uneditable">loop_</span>
</div>
<ng-container *ngFor='let col of loop.tags'>
    <div class="line highlight">
        <span class="uneditable">{{loop.category + '.' + col}}</span>
    </div>
</ng-container>
<table class="alternating_color">
    <tbody>
    <tr *ngFor='let row of loop.data'>
        <ng-container *ngFor='let datum of row'>
            <td>
                <label>
                    <input class="datum highlight" [(ngModel)]="datum.value" placeholder="."
                           [style.width.ch]='(datum.value !== null) ? datum.value.length + 2 : 2'>
                </label>
            </td>
        </ng-container>
    </tr>
    </tbody>
</table>
<div class="line highlight one_indent">
    <span class="uneditable">stop_</span>
</div>
