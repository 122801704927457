<div class="main-content">
    <mat-card>
        <mat-card-header>
            <mat-card-title *ngIf="!(entry.valid && entry.emailValidated)">You are viewing all sections of your deposition that still need to be
                completed.
            </mat-card-title>
            <mat-card-title *ngIf="entry.valid && entry.emailValidated">Your deposition is ready to submit.</mat-card-title>
        </mat-card-header>
        <mat-card-content>
            <mat-nav-list>
                <ng-container *ngFor="let superGroupCat of entry.superGroups">
                    <ng-container *ngFor="let category of superGroupCat.children">
                        <p *ngIf="(!category.valid  && category.display != 'H')">
                            <a mat-raised-button [routerLink]="['/entry', 'saveframe', category.category]" color="accent"
                               matTooltip="Jump to this section.">
                                {{entry.schema.saveframeSchema[category.category]['category_group_view_name']}}</a>
                        </p>
                    </ng-container>
                </ng-container>
              <a *ngIf="!entry.emailValidated" mat-raised-button [routerLink]="['/entry', 'pending-verification']" color="accent">
                Verify Email Address</a>
            </mat-nav-list>
          <mat-form-field *ngIf="entry.valid && entry.emailValidated" class="content-box" color="accent">
            <label>
              <input matInput placeholder="Do you have any feedback on the BMRBdep system?" [formControl]="messageControl">
            </label>
          </mat-form-field>
        </mat-card-content>
        <mat-card-actions>
          <h5 *ngIf="api.saveInProgress">You cannot deposit until you have finished uploading the data files / metadata changes. Please wait
            for the deposition system to stop displaying a progress bar at the top of the page before continuing.</h5>
            <button mat-raised-button class="saveframe-button" [disabled]="!(entry.valid && entry.emailValidated && !api.saveInProgress && !entry.unsaved)" color="accent"
                    (click)="submitEntry()">Submit deposition to BMRB
            </button>
            <a mat-raised-button (click)="goBack()" color="accent">Return to previous page</a>
        </mat-card-actions>
    </mat-card>
</div>
